<template lang="pug">
include ../pug/svg
div#access(v-if="course")
	div#ctrl
		div
			Search(v-model:search="search")
			Filter(v-if="groupFilters.length" id="groupFilter" :label="terminology('user','group','singular')" :title="terminology('user','group','singular')" :options="groupFilters" v-model:selected="groupFilter")
			Filter(id="roleFilter" label="Role" title="Roles" :options="roleFilters" v-model:selected="roleFilter")
	div.wrapper
		h6
			button.but.circ.dblfwdarr.text(type="button" @click="assignUsers(unassignedUsers)" :disabled="!unassignedUsers.length")
				span Assign All
				+svg(svg-filename="iconDoubleArrow" aria-hidden="true" alt="Assign all icon")
			span Unassigned ({{unassignedUsers.length}})
		h6
			span Assigned ({{assignedUsers.length}})
			button.but.circ.dblbckarr.text.flip(type="button" @click="unassignUsers(assignedUsers)" :disabled="!assignedUsers.length")
				span Unassign All
				+svg(svg-filename="iconDoubleArrow" aria-hidden="true" alt="Unassign all icon")
	div.wrapper
		div.listbg
			ul.list.unassigned
				li.person(v-for="(courseUser, index) in unassignedUsers" :key="courseUser.user.id" :user="courseUser" :index="index")
					//-picture.user(v-if="courseUser.user.profileUrl" :class="{online:userOnline(courseUser.user.id)}")
					picture.user(v-if="courseUser.user.profileUrl" :class="{online:courseUser.user.online}")
						img(:src="courseUser.user.profileUrl" width="40" height="40" :alt="courseUser.user.fullName")
					span.user(v-else :class="{online:courseUser.user.online}") {{courseUser.user.initials}}
					div.hgroup
						h2 {{courseUser.user.fullName}}
						h3 {{courseUser.user.publishStatus}} {{terminology('user', courseUser.user.userOrganisations[0].role.slug,'singular')}}
							span.email {{courseUser.user.email}}
					button.but.circ.fwdarr(type="button" @click="assignUsers(courseUser)")
						span Assign User
						+svg(svg-filename="iconArrow" aria-hidden="true" alt="Assign icon")
		div.listbg
			ul.list.assigned
				li.person(v-for="(courseUser, index) in assignedUsers" :key="courseUser.user.id" :user="courseUser" :index="index")
					//-picture.user(v-if="courseUser.user.profileUrl" :class="{online:userOnline(courseUser.user.id)}")
					picture.user(v-if="courseUser.user.profileUrl" :class="{online:courseUser.user.online}")
						img(:src="courseUser.user.profileUrl" width="40" height="40" :alt="courseUser.user.fullName")
					span.user(v-else :class="{online:courseUser.user.online}") {{courseUser.user.initials}}
					div.hgroup
						h2 {{courseUser.user.fullName}}
						div
							Filter(title="Role" :options="roleFilters" :selected="getRole(courseUser.roleId)" @update:selected="setRole($event, courseUser.user)" :clear="false")
							span.email {{courseUser.user.email}}
					button.but.circ.bckarr(type="button" @click="unassignUsers(courseUser)")
						span Unassign User
						+svg(svg-filename="iconArrow" aria-hidden="true" alt="Unassign icon")
</template>

<script>
import Search from '../components/Search';
import Filter from '../components/Filter';
//import { mapFields } from 'vuex-map-fields';

export default {
	name: 'ManageLearningCourseAccess',
	props: ['course'],
	emits: ['storeUpdated'],
	components: {
		Search,
		Filter,
	},
	data() {
		return {
			search: null,
			groupFilter: null,
			roleFilter: null,
		};
	},
	mounted() {
		this.refreshUsers();		
	},
	watch: {
		course: {
			handler(course) {
				this.$store.dispatch('gui/setHeader', {
					title: course.name,
					backRoute: {
						text: this.terminology('course','course','plural'),
						name: 'ManageLearning',
					},
				});		
			},
			deep: false,
		},
	},
	computed: {
		users() {
			// organisation users
			const users = this.$store.state.users.users.map(u => {
				let roleId = null;

				// check if user assigned to course
				if (this.course?.courseUsers) {
					const courseUser = this.course.courseUsers.find(cu => cu.userOrganisation.userId === u.id) || null;

					if (courseUser) {
						roleId = courseUser.roleId;
					}
				}

				return {
					roleId: roleId,
					user: u
				};
			});

			return users.sort((a, b) => {
				return a.user.fullName.localeCompare(b.user.fullName);
			});
		},
		roleFilters() {
			const roles = this.$store.state.users.userRoles.filter(r => r.seniority >= 50); // mentor or learner
			// map value/option pairs to group data
			return roles.map(r => {
				return {
					value: r.id,
					option: this.terminology('user', r.slug, 'singular')
				};
			});
		},
		groupFilters() {
			const groups = this.$store.state.gui.organisation.organisationGroups;
			// map value/option pairs to group data
			return groups.map(g => {
				return { value: g.id, option: g.name };
			});
		},
		filteredUsers() {
			// remove archived users
			let activeUsers = this.users.filter(u => u.user.userOrganisations[0].status !== 'archived');

			// apply user group filter
			const groupSelected = this.groupFilter?.value || null;
			activeUsers = (groupSelected) ? activeUsers.filter(u => u.user.userOrganisations[0].userOrganisationGroups.map(g => g.organisationGroupId).includes(groupSelected)) : activeUsers;
			
			// apply user role filter
			const roleSelected = this.roleFilter?.value || null;
			activeUsers = (roleSelected) ? activeUsers.filter(u => u.roleId === roleSelected) : activeUsers;
			
			// serch term filter
			return (this.search) ? activeUsers.filter(u => u.user.fullName.toLowerCase().includes(this.search.toLowerCase())) : activeUsers;
		},
		assignedUsers: {
			get() {
				const assignedUserIds = this.course?.courseUsers.map(u => u.userOrganisation.userId) || [];
				return this.filteredUsers.filter(u => assignedUserIds.includes(u.user.id));
			},
			set() {
			}
		},
		unassignedUsers: {
			get() {
				const assignedUserIds = this.course?.courseUsers.map(u => u.userOrganisation.userId) || [];
				return this.filteredUsers.filter(u => !assignedUserIds.includes(u.user.id));
			},
			set() {
			}
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		async refreshUsers() {
			// load users from db
			await this.$store.dispatch('users/dbUsers');
		},
		/*userOnline(userId) {
			return this.$store.getters['users/userOnline'](userId);
		},*/		
		getRole(roleId) {
			return this.roleFilters.find((r) => r.value === roleId);
		},
		setRole(selected, user) {
			this.$store.dispatch('courses/assignUserRole', {
				courseId: this.course.id,
				userId: user.id,
				roleId: selected.value,
			});
			
			this.$emit('storeUpdated', {valid:true});
		},
		assignUsers(courseUser) {
			const courseUsers = Array.isArray(courseUser) ? courseUser : [courseUser];
			
			this.$store.dispatch('courses/assignUsers', {
				courseId: this.course.id,
				courseUsers: courseUsers,
			});
			
			this.$emit('storeUpdated', {valid:true});
		},
		unassignUsers(courseUser) {
			const courseUsers = Array.isArray(courseUser) ? courseUser : [courseUser];
			
			this.$store.dispatch('courses/unassignUsers', {
				courseId: this.course.id,
				courseUsers: courseUsers,
			});
			
			this.$emit('storeUpdated', {valid:true});
		},
	},
}
</script>

<style lang="scss">
#access {
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-grow: 1;
	.wrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;
		>* {
			width: calc(50% - 15px);
		}
		+.wrapper {
			flex-grow: 1;
		}
	}
	.list {
		&.assigned {
			.person {
				flex-direction: row-reverse;
			}
		}
		>li {
			padding-right: 20px;
		}
		.filter {
			.input {
				line-height: 1;
				font-size: 1rem;
				color: var(--pan-lnk);
				text-transform: var(--lab-uc);
				font-weight:  var(--lab-wgt);
				height: auto;
			}
		}
	}
	h6 {
		order: -1;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 10px;
		padding: 0 10px;
		font-size: 1rem;
		font-weight: var(--lab-wgt);
		text-transform: var(--lab-uc);
		color: var(--lab-col);
	}
}
</style>
